import React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-light-svg-icons';

library.add(faAngleRight, faAngleLeft);

const Pagination = ({ path, numPages, currentPage }) => {
  return (
    <ul css={PaginationStyle}>
      <li className="prev">
        { (1 === currentPage-1) && <Link to={path}>
          <FontAwesomeIcon className="icon" icon={["fal", "angle-left"]} />
          Prev
        </Link> }
        { (1 < currentPage-1) && <Link to={`${path}/${currentPage-1}`}>
          <FontAwesomeIcon className="icon" icon={["fal", "angle-left"]} />
          Prev
        </Link> }
      </li>
      <li className="current">
        {currentPage} / {numPages}
      </li>
      <li className="next">
        { (currentPage+1 <= numPages) && <Link to={`${path}/${currentPage+1}`}>
          <FontAwesomeIcon className="icon" icon={["fal", "angle-right"]} />
          Next
        </Link> }
      </li>
    </ul>
  );
}

export default Pagination;

const PaginationStyle = css`
  display: flex;
  justify-content: space-between;
  margin-block-start: 2rem;
  margin-block-end: 2rem;
  padding-inline-start: 0;

  list-style: none;
  color: #ACAEAF;

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child {
    margin-block-end: 0;
  }

  .prev,
  .next {
    a {
      display: block;
      color: #ACAEAF;

      &:hover {
        text-decoration: none;
        color: #4D4F50;
      }
    }
    &:after {
      clear: both;
    }
  }

  .prev .icon {
    float: left;
    height: 1.5rem;
    margin-right: 1em;
    vertical-align: -0.25em;
  }
  .next .icon {
    float: right;
    height: 1.5rem;
    margin-left: 1em;
    vertical-align: -0.25em;
  }
`;
