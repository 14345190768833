import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/default";
import SEO from "../components/seo";

import PageHeader from "../components/page-header";
import { Section, Container } from "../styles/layouts";
import { AchievementList, AchievementCard } from "../components/achievement-card";
import Pagination from "../components/pagination";

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allLatestAchievementsJson (
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          date
          slug
          title
          url
          profile {
            slug
            profile {
              name {
                full
              }
            }
            images {
              face
            }
          }
        }
      }
    }
  }
`;


export default ({ data, pageContext }) => {

  return (
    <Layout>
      <SEO title="最近のアチーブメント" description="FC Assort に所属するメンバーの最近のアチーブメント一覧です。" />
      <main>
        <PageHeader title="Latest Achievements" />
        <Section>
          <Container>
            <Pagination path={`/latest-achievements`} numPages={pageContext.numPages} currentPage={pageContext.currentPage} />
            <AchievementList>
              {data.allLatestAchievementsJson.edges.map(({node}, index) => (
                <AchievementCard key={index} achievement={node} />
              ))}
            </AchievementList>
            <Pagination path={`/latest-achievements`} numPages={pageContext.numPages} currentPage={pageContext.currentPage} />
          </Container>
        </Section>
      </main>
    </Layout>
  )
}
