import React from "react";
import Imgix from "react-imgix";
import { css } from "@emotion/react";

const CharaFaceImg = (props) => {

  return (<Imgix
    css={CharaFaceStyle}
    width={48}
    height={48}
    src={`${process.env.GATSBY_PROFILE_IMGIX_URL}/face_image/${props.filename}`}
    imgixParams={{
      q: 100,
      auto: 'compress,enhance,format',
      sharp: 15
    }}
    {...props} />
  );
}

export default CharaFaceImg;

const CharaFaceStyle = css`
  display: block;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255,255,255,.5);
  background-color: rgba(255,255,255,.25);
`;
